.aboutus_1
{
    width: 100%;
    height: auto;
    padding-top: 0.8rem;
    padding-bottom: 0.8rem;
    display: grid;
    overflow: hidden;
    grid-template-columns: 40% 60%;
}

.aboutus_1_title
{
    grid-column-start: 1;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.aboutus_1_p
{
    grid-column-start: 2;
    width: 90%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.aboutus_2 {
    margin-top: 1.1rem;
    margin-bottom: 1.1rem;
    width: 100%;
    padding-top: 1.2rem;
    padding-bottom: 1.2rem;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden; 
}

.image1 {
    width: 92%; /* Ensures the image fills the width of the container */
    height: auto; /* Maintains aspect ratio */
    object-fit: cover; /* Ensures the image covers the container without stretching */
    border-radius: 2.2rem;
}

.image2 {
    max-width: 62%; /* Ensures the image fills the width of the container */
    height: auto; /* Maintains aspect ratio */
    object-fit: cover; /* Ensures the image covers the container without stretching */
    border-radius: 2.2rem;
}



.image3 {
  max-width: 72%; /* Ensures the image fills the width of the container */
  height: auto; /* Maintains aspect ratio */
  object-fit: cover; /* Ensures the image covers the container without stretching */
  border-radius: 2.2rem;
}


.image2,.image3 {
    max-width: 62%; /* Ensures the image fills the width of the container */
    height: auto; /* Maintains aspect ratio */
    object-fit: cover; /* Ensures the image covers the container without stretching */
    border-radius: 2.2rem;
}



.container 
{  
    display: grid;
    padding-top: 2.2rem;
    padding-bottom: 2.2rem;
    height: auto;
    grid-template-columns: 50% 50%;
    grid-template-rows: 100%;
    grid-auto-columns: 1fr;
    gap: 0px 0px;
    grid-auto-flow: row;
    grid-template-areas:
      "Aboutus3_image Aboutus3_titel";
}
  
.Aboutus3_image 
{ 
    grid-area: Aboutus3_image; 
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
  
  .Aboutus3_titel 
{ 
    width: 90%;
    display: flex;
    align-items: end;
    justify-content: center;
    flex-direction: column;
    grid-area: Aboutus3_titel; 
}



.container2
{  
    display: grid;
    margin-bottom: 1.1rem;
    margin-top: 1.1rem;
    padding-top: 2.4rem;
    padding-bottom: 2.4rem;
    grid-template-columns: 60% 40%;
    grid-template-rows: 100%;
    grid-auto-columns: 1fr;
    gap: 0px 0px;
    background-color: #DDC7BB;
    grid-auto-flow: row;
    grid-template-areas:
      "Aboutus4_titel Aboutus4_image ";
}
  
.Aboutus4_image 
{ 
    grid-area: Aboutus4_image; 
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
  
  .Aboutus4_titel 
{ 
    margin-left: 2.1rem;
    width: 100%;
    display: flex;
    align-items: end;
    justify-content: center;
    flex-direction: column;
    grid-area: Aboutus4_titel; 
}
  



.container3
{  
    display: grid;
    margin-bottom: 1.1rem;
    margin-top: 1.1rem;
    padding-top: 2.4rem;
    padding-bottom: 2.4rem;
    grid-template-columns: 60% 40%;
    grid-template-rows: 100%;
    grid-auto-columns: 1fr;
    gap: 0px 0px;
    background-color: white;
    grid-auto-flow: row;
    grid-template-areas:
      "Aboutus4_titel Aboutus4_image ";
}


#Aboutus
{
  width: 42%;
}

#Aboutus_2
{
  width: 32%;
}


/* Tablet */
@media only screen and (min-width: 768px) and (max-width: 1024px) {
    
  }
  
  /* Mobile */
  @media only screen and (max-width: 767px) {
  
    .aboutus_1
    {
        width: 100%;
        height: 45vh;
        display: grid;
        overflow: hidden;
        grid-template-columns: 100%;
        grid-template-rows: 20% 80%;
    }

    .aboutus_1_title
    {
        grid-column-start: 1;
        grid-row-start: 1;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        text-align: center;
    }

    .aboutus_1_p
    {
        grid-column-start: 1;
        grid-row-start: 2;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        text-align: center;
    }

    .container {
        grid-template-columns: 100%; /* Stack content vertically on mobile */
        grid-template-rows: auto; /* Allow rows to adjust to content height */
        grid-template-areas:
          "Aboutus3_titel"
          "Aboutus3_image"; /* Image on top, title below */
        gap: 15px 0px; /* Add vertical spacing between image and title */
      }

      .container2 {
        grid-template-columns: 100%; /* Stack content vertically on mobile */
        grid-template-rows: auto; /* Adjust rows to fit content height */
        grid-template-areas:
          "Aboutus4_titel"
          "Aboutus4_image"; /* Title on top, image below */
        gap: 15px 0px; /* Add vertical spacing between title and image */
      }

      .Aboutus3_titel
      {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 100vw;
        text-align: center;
        margin: 0;
      }

      .container3
      {  
         display: none;
      }


    
    
  }

