a {
    text-decoration: none;
}



.logo
{
    width: 28%;
}

#mobile_menu
{
    display: none;
}

.header_title
{
    width: 100%;
    height: 100%;
    grid-column-start: 2;
    cursor: pointer;
}

.contact
{
    width: 100%;
    height: 100%;
    grid-column-start: 4;
    display: flex;
    align-items: center;
    justify-content: center;
}

.contact_button
{
    padding-left: 2.4rem;
    padding-right: 2.4rem;
    padding-top: 0.8rem;
    padding-bottom: 0.8rem;
    background-color: #2A1B12;
    color: white;
    border: none;
    border-radius: 0.5rem;
    transition: transform 0.3s ease;
}


.header_h1
{
    color: #2A1B12;
    font-weight: bold;
}

.header_h3
{
    color: #2B1B12;
    cursor: pointer;
    transition: transform 0.3s ease;
}

.header_h3:hover {
    transform: scale(1.2); /* Scale up to 1.1 on hover */
}

.contact_button:hover
{
    transform: scale(1.2); /* Scale up to 1.1 on hover */
}







.header {
    position: sticky;
    top: 0;
    width: 100%;
    background-color: #FFF;
    z-index: 999;
  }
  
  nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 30px;
  }
  
  
  
  .menu {
    display: flex;
    justify-content: center;
    align-items: space-between;
  }
  
  .menu a {
    display: block;
    padding: 7px 15px;
    margin-left: 1.1rem;
    margin-right: 1.1rem;
    font-size: 17px;
    font-weight: 500;
    transition: 0.2s all ease-in-out;
    color: #000;
  }
  
  .menu:hover a {
    opacity: 0.4;
  }
  
  .menu a:hover {
    opacity: 1;
    color: #000;
  }
  
  .menu-icon {
    display: none;
  }
  
  #menu-toggle {
    display: none;
  }
  
  #menu-toggle:checked ~ .menu {
    transform: scale(1, 1);
  }
  
  @media only screen and (max-width: 950px) {
    
    
    .logo
    {
        width: 48%;
    }
    
    
    
    .menu {
      flex-direction: column;
      background-color: #FFF;
      align-items: start;
      position: absolute;
      top: 70px;
      left: 0;
      width: 100%;
      z-index: 999;
      transform: scale(1, 0);
      transform-origin: top;
      transition: transform 0.3s ease-in-out;
      box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    }
  
    .menu a {
      margin-left: 12px;
    }
  
    .menu li {
      margin-bottom: 10px;
    }
  
    .menu-icon {
      display: block;
      color: #000;
      font-size: 28px;
      cursor: pointer;
    }
  }
  




