.terms
{
    width: 100vw;
    height: auto;
    padding-top: 1.1rem;
    padding-bottom: 1.1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.terms_center
{
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}


