@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

::-webkit-scrollbar {
    width: 0px;
    /* Remove scrollbar space */
    background: transparent;
    /* Optional: just make scrollbar invisible */
}


html,body
{
    text-overflow: ellipsis;
    background: white;
    margin: 0;
    padding: 0;
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-style: normal;
    overflow-x: hidden;
}

ul {
    list-style-type: none; /* Removes default bullet points */
}

/* Add these styles to your faq.css */
.arabic-text {
    text-align: right;
    direction: rtl; /* Sets text direction to right-to-left */
}

.text_left
{
    text-align: right;
}

.text_center
{
    text-align: center;
}



.spilt_layout
{
    width: 100%;
    height: auto;
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-columns: 1fr;
}

.spilt_layout_1
{
    width: 100%;
    height: auto;
    grid-column-start: 1;
    text-align: left;
}


.spilt_layout_2
{
    width: 100%;
    height: auto;
    grid-column-start: 2;
    text-align: right;
}


.aboutus_p_english
{
    text-align: left;
    direction: ltr;
}




  /* Mobile */
  @media only screen and (max-width: 767px) {


    .spilt_layout
    {
        width: 100%;
        height: auto;
        display: grid;
        grid-template-columns: 100%;
        grid-template-rows: auto auto;
    }

    .spilt_layout_1
    {
        width: 100%;
        height: auto;
        grid-column-start: 1;
        grid-row-start: 1;
        text-align: left;
    }


    .spilt_layout_2
    {
        width: 100%;
        height: auto;
        grid-column-start: 1;
        grid-row-start: 2;
        text-align: right;
    }

  }

