.objectives
{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;

}


.margin_bottom_zero
{
  margin-bottom: 0;
}


.departments_layout
{
    display: flex;
    width: 100%;
    height: auto;
    padding-top: 1.1rem;
    padding-bottom: 1.1rem;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
   
}



.container {  
    margin-top: 1.2rem;
    margin-bottom: 1.2rem;
    height: auto;
    width: 100vw;
    overflow: hidden;
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: 1fr;
    grid-auto-columns: 1fr;
    gap: 2% 2%;
    grid-auto-flow: row;
  }
  
  .Something-Else-Image 
  { 
    grid-column-start: 1;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  
  .Something-else-Text 
  { 
    grid-column-start: 2;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
  }

  .something_Image
  {
    max-width: 52%;
    height: auto;
    border-radius: 2.2rem;
  }
  
  .something_p
  {
    width: 75%;
  }

  .highlights
  {
    width: 55%;
    height: 25%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }


  .hero
  {
    margin-top: 1.1rem;
    margin-bottom: 1.1rem;
    width: 100vw;
    height: 68vh;
    display: grid;
    z-index: 1;
    grid-template-columns: 10% 45% 45%;
    grid-template-rows: 100%;
  }

  .hero_text
  {
    grid-column-start: 2;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
  }

  .hero_title
  {
    font-size: 3.2rem;
    margin: 0;
  }

  .hero_image
  {
    grid-column-start: 3;
    width: 100%;
    height: 100%;
    background-size: 88%;
    background-position: center right;
    background-repeat: no-repeat;
    transition: background-image 1s ease-in-out;
  }
  

  .hero_contact
  {
    width: 100vw;
    height: 4.2rem;
    z-index: 1000;
    margin-top: -3.4rem;
    margin-bottom: 3.4rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .hero_contact_section
  {
    padding-top: 2.2rem;
    padding-bottom: 2.2rem;
    width: 76%;
    height: 86%;
    background-color: #DDC7BB;
    border-radius: 1.2rem;
    display: grid; 
    grid-auto-columns: 1fr; 
    grid-template-columns: 25% 25% 25% 15%; 
    grid-template-rows: 100%; 
    gap: 0% 2%; 
    grid-template-areas: 
    "Email Contact whats-app Location"; 
    align-items: center; /* Center items vertically */
    justify-items: center; /* Center items horizontally */
  }

  .Email 
  { 
    grid-area: Email;
    border-radius: 0.4rem;
    width: 92%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: row;
    height: 92%;
    background-color: #FBF5F1;
  }
  .Contact 
  { 
    border-radius: 0.4rem;
    width: 92%;
    height: 92%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: row;
    background-color: #FBF5F1;
    grid-area: Contact; 
  }
  .whats-app 
  { 
    border-radius: 0.4rem;
    width: 92%;
    height: 92%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: row;
    background-color: #FBF5F1;
    grid-area: whats-app; 
  }
  .Location 
  { 
    grid-area: Location; 
    width: 85%;
    border-radius: 0.4rem;
    height: 92%;
    display: flex;
    color: white;
    align-items: center;
    justify-content: center;
    background-color: #2B1B12;
  }



  .sales
  {
    margin-top: 2.2rem;
    padding-top: 2.2rem;
    padding-bottom: 2.2rem;
    margin-bottom: 2.2rem;
    width: 100vw;
    height: auto;
    display: flex;
    background-color: #FEF7F2;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
  }


  .sales_grid
  {
    padding-bottom: 2.1rem;
    width: 78%;
    height: auto;
    display: grid;
    grid-template-columns: 32% 32% 32%;
    column-gap: 1%;
  }


  .salesCard1,.salesCard2,.salesCard3
  {
    grid-column-start: 1;
    padding-top: 1.1rem;
    padding-bottom: 1.1rem;
    width: 85%;
    height: auto;
    border-radius: 2.2rem;
    background-color: #DDC7BB;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 0;
  }

  .salesCard2
  {
    grid-column-start: 2;
  }

  .salesCard3
  {
    grid-column-start: 3;
  }

  .salesimage
  {
    border-radius: 1.1rem;
    width: 85%;
    background-color: white;
    height: 310px;
    border: none;
    object-fit: contain; /* Options: contain, cover, fill, scale-down, none */
  }

 

/* Tablet */
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .objectives_h1,.objectives_h3
  {
    font-size: medium;
  }

  .hero
  {
    margin-top: 1.1rem;
    margin-bottom: 1.1rem;
    width: 100vw;
    height: auto;
    display: grid;
    z-index: 1;
    grid-template-columns: 4% 45% 50%;
    grid-template-rows: 100%;
  }

  .sales_grid
  {
    padding-bottom: 2.1rem;
    width: 88%;
    height: auto;
    display: grid;
    grid-template-columns: 32% 32% 32%;
    gap: 1.2rem;
  }

  .salesCard1,.salesCard2,.salesCard3
  {
    padding-top: 1.1rem;
    padding-bottom: 1.1rem;
    padding-left: 1.1rem;
    padding-right: 1.1rem;
  }
}

/* Mobile */
@media only screen and (max-width: 767px) {

  .objectives_h1,.objectives_h3
  {
    font-size: medium;
  }

  .hero{
    display: flex;
    align-content: center;
    justify-content: center;
    flex-direction: column-reverse;
    text-align: center;
    font-size: medium;
  }

  .hero_title
  {
    display: none;
  }

  .hero_title_h3
  {
    margin: 0;
  }

  .hero_text
  {
    width: 86%;
    height: auto;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
  }
  

  #somethingelse {  
    height: auto;
    text-align: center;
    width: 100vw;
    overflow: hidden;
    display: flex;
    font-size: medium;
    align-items: center;
    justify-content: center;
    flex-direction: column-reverse;
  }
  
  .Something-Else-Image 
  { 
    text-align: center;
    width: 100%;
    height: 100%;
  }

  .highlights
  {
    display: none;
  }
  
  .Something-else-Text 
  { 
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
    height: 100%;
  }
  

  .sales_grid {
    padding-bottom: 1.1rem;
    width: 100vw;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 1.4rem; /* Add gap for consistent spacing */
  }

  .salesCard1, .salesCard2, .salesCard3 {
    grid-column-start: 1;
    padding-top: 2.1rem;
    padding-bottom: 1.1rem;
    width: 85%;
    height: auto;
    border-radius: 2.2rem;
    background-color: #DDC7BB;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 0; /* Remove margin-bottom */
  }

  .something-else-h1
  {
    font-size: medium;
  }



  
}
  

