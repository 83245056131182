.sale_marquee_layout
{
  margin-top: 1.2rem;
  width: 100vw;
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
  background-color: #DDC7BB;
}

.sale_marquee
{
  margin-left: 1.2rem;
  margin-right: 1.2rem;
  z-index: 1;
}

.sale_highlight_layout
{
  width: 100vw;
  height: auto;
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.sales_highligh_box
{
  width: 85%;
  height: 92%;
  border-radius: 1.2rem;
  background-color: #FEF7F2;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.highlight_points
{
  width: 100%;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  padding-top: 1.1rem;
  padding-bottom: 1.1rem;
  height: auto;
}




.container_shop {
  display: grid;
  margin-top: 2.2rem;
  margin-bottom: 2.2rem;
  width: 100vw;
  height: auto; 
  grid-auto-columns: 1fr; 
  grid-template-columns: 23% 23% 23% 23%; 
  grid-template-rows: 100%; 
  align-items: center;
  justify-content: center;
  gap: 1% 1%; 
  grid-template-areas: 
    "Product1 Product2 Product3 Product4"; 
}

  .Product1 
  { 
    grid-area: Product1; 
    width: 92%;
    height: 100%;
    border-radius: 1.2rem;
    background-color: #DDC7BB;
    display: flex;
    padding-top: 1.1rem;
    padding-bottom: 1.1rem;
    align-items: center;
    justify-content: center;
    flex-direction: column;

  }
  .Product2 { 
    grid-area: Product2;
    width: 92%;
    height: 100%;
    border-radius: 1.2rem;
    padding-top: 1.1rem;
    padding-bottom: 1.1rem;
    background-color: #DDC7BB; 
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

  }
  .Product3 
  { 
    grid-area: Product3;
    width: 92%;
    height: 100%;
    border-radius: 1.2rem;
    padding-top: 1.1rem;
    padding-bottom: 1.1rem;
    background-color: #DDC7BB;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column; 
  
  }
  .Product4 
  { 
    grid-area: Product4;
    width: 92%;
    height: 100%;
    border-radius: 1.2rem;
    padding-top: 1.1rem;
    padding-bottom: 1.1rem;
    background-color: #DDC7BB;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column; 
  }

  .product_image
  {
    width: 85%;
    border-radius: 1.2rem;
    height: auto;
  }

  .product_title,.product_des
  {
    width: 92%;
    text-align: center;
    margin-bottom: 0;
  }



  /* Tablet */
  @media only screen and (min-width: 768px) and (max-width: 1024px) {
    .sales_highligh_h3
    {
      text-align: center;
      margin-left: 1.1rem;
      margin-right: 1.1rem;
    }

    .container_shop {
      grid-template-columns: 48% 48%;
      grid-template-rows: auto auto;
      grid-template-areas: 
        "Product1 Product2"
        "Product3 Product4";
      gap: 2.2rem 0; /* Horizontal gap of 1%, no vertical gap */
      margin-top: 2.1rem;
      margin-bottom: 2.1rem;
    }
  
    /* Add margin below each product card */
    .Product1, .Product2, .Product3, .Product4 {
      margin-bottom: 2.2rem; /* Adjust this value as needed */
      padding: 0.1rem;
    }


    .sale_highlight_layout
    {
      margin-top: 1.1rem;
      margin-bottom: 1.1rem;
    }
}

/* Mobile */
@media only screen and (max-width: 767px) {
  .sales_highligh_h3
  {
    text-align: center;
    margin-left: 0.4rem;
    margin-right: 0.4rem;
  }

  .container_shop {
    display: grid;
    height: auto;
    grid-template-columns: repeat(2, 1fr); /* 2 columns with equal width */
    grid-template-rows: auto;
    grid-template-areas: 
      "Product1 Product2"
      "Product3 Product4";
    gap: 1rem; /* Equal gap between grid items */
    margin: 1rem; /* Equal margin around the grid */
  }

  .Product1, .Product2, .Product3, .Product4 {
    width: 85%; /* Full width of the grid cell */
    height: 100%; /* Adjust height as needed */
    border-radius: 1.2rem;
    background-color: #DDC7BB;
    display: flex;
    font-size: medium;
    padding: 0.2rem;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .sale_highlight_layout {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .product_title
  {
    font-size: medium;
  }
  .product_des
  {
    display: none;
  }



}