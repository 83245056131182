.faq
{
  width: 100%;
  height: auto;
  padding-top: 2.1rem;
  padding-bottom: 2.1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}


/* Add these styles to your faq.css */
.arabic-text {
  text-align: right;
  direction: rtl; /* Sets text direction to right-to-left */
}


.faq_context
{
  width: 65%;
  height: auto;
}


/* Tablet */
@media only screen and (min-width: 768px) and (max-width: 1024px) {
   
  
  .faq
  {
    padding-top: 1.1rem;
    padding-bottom: 1.1rem;
  }

  .faq_context
  {
    width: 84%;
    height: auto;
  }


}

/* Mobile */
@media only screen and (max-width: 767px) {
  
  .faq_context
  {
    width: 82%;
    height: auto;
  }

}

#contact_faq_button:hover
{
  scale: 1;
}

.faq_button_layout
{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: auto;
  padding-top: 1.2rem;
  padding-bottom: 1.2rem;
}