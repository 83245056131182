.departments
{
    width: 100%;
    height: auto;
    margin-top: 4.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.margin_zero
{
    margin: 0;
}

.department_grid
{
   width: 66%;
   height: auto;
   display: grid; 
   margin-bottom: 2.2rem;
   grid-auto-columns: 1rem; 
   grid-template-columns: 32% 32% 32%; 
   grid-template-rows: auto auto auto; 
   gap: 1.2rem 1.2rem; 
   grid-template-areas: 
    "Cnc Conventional Electrical"
    "Mold Sheet-Metal Welding"
    "Contact GearBox Location"; 
}

.Cnc 
{
    grid-area: Cnc;
    cursor: pointer;
    width: 100%;
    height: 100%;
    border-radius: 1.1rem;
    background-color: #DDC7BB;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column; 
}

.Conventional 
{ 
    grid-area: Conventional;
    cursor: pointer;
    width: 100%;
    height: 100%;
    border-radius: 1.1rem;
    background-color: #DDC7BB;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column; 
}

.Electrical 
{ 
    grid-area: Electrical; 
    cursor: pointer;
    width: 100%;
    height: 100%;
    border-radius: 1.1rem;
    background-color: #DDC7BB;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
}
.Mold 
{ 
    grid-area: Mold;
    cursor: pointer;
    width: 100%;
    height: 100%;
    border-radius: 1.1rem;
    background-color: #DDC7BB;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
}
.Sheet-Metal 
{ 
    grid-area: Sheet-Metal;
    cursor: pointer;
    width: 100%;
    height: 100%;
    border-radius: 1.1rem;
    background-color: #DDC7BB; 
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
}
.Welding 
{ 
    grid-area: Welding;
    cursor: pointer;
    width: 100%;
    height: 100%;
    border-radius: 1.1rem;
    background-color: #DDC7BB;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column; 
}
.GearBox 
{ 
    grid-area: GearBox; 
    cursor: pointer;
    width: 100%;
    height: 100%;
    border-radius: 1.1rem;
    background-color: #DDC7BB;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
}


.Contact 
{ 
    grid-area: Contact; 
    cursor: pointer;
    width: 100%;
    height: 100%;
    border-radius: 1.1rem;
    background-color: #DDC7BB;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
}



.Location 
{ 
    grid-area: Location; 
    cursor: pointer;
    width: 100%;
    height: 100%;
    border-radius: 1.1rem;
    background-color: #DDC7BB;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
}

.logo_grid
{
    max-width: 2.7rem;
    max-height: 2.7rem;
    margin-left: 8%;
    margin-top: 8%;
    padding: 1.2rem;
    border-radius: 0.6rem;
    background-color: #FBF5F1;
}


.logo_grid_2
{
    max-width: 2.7rem;
    max-height: 2.7rem;
    margin-top: 8%;
    padding: 1.2rem;
    border-radius: 0.6rem;
    background-color: #FBF5F1;
}



.logo_title
{
    color: #2B1B12;

    margin-left: 8%;
    margin-top: 4%;
    margin-bottom: 0;
}

.logo_desc
{
    width: 85%;
    text-align: center;
    color: #2B1B12;
    margin-left: 8%;
    margin-bottom: 2.2rem;
}

.metalforming
{
    width: 85%;
    image-rendering: optimizeQuality;
}


/* Tablet */
@media only screen and (min-width: 768px) and (max-width: 1024px) {

    .departments
    {
        text-align: center;
    }

    .department_grid
    {
    width: 92%;
    height: auto;
    display: grid; 
    margin-bottom: 1.2rem;
    grid-auto-columns: 1rem; 
    grid-template-columns: 32% 32% 32%; 
    grid-template-rows: 32% 32% 32%; 
    gap: 1.1rem 1.1rem; 
    grid-template-areas: 
        "Cnc Conventional Electrical"
        "Mold Sheet-Metal Welding"
        "Contact GearBox Location"; 
    }
    
}

/* Mobile */
@media only screen and (max-width: 767px) {

    .departments {
        text-align: center;
    }

    .department_grid {
        width: 92%;
        height: auto;
        display: grid;
        grid-auto-columns: auto;
        grid-template-columns: 100%;
        grid-template-rows: auto; /* Adjusted to auto so rows size based on content */
        margin-bottom: 0;
        gap: 1.1rem 1.1rem;
        grid-template-areas:
            "Cnc"
            "Conventional"
            "Electrical"
            "Mold"
            "Sheet-Metal"
            "Welding"
            "Contact"
            "GearBox"
            "Location";
    }

    /* Ensure all cards have the same height */
    .Cnc,
    .Conventional,
    .Electrical,
    .Mold,
    .Sheet-Metal,
    .Welding,
    .Contact,
    .GearBox,
    .Location {
        height: auto;
        min-height: 150px; /* Set a minimum height if needed for uniformity */
    }

    
    .Aboutus3_image,.metalforming
    {
        display: none;
    }

    #about_us_h1
    {
        font-size: medium;
    }


    
  
}

