.footer
{
    width: 100%;
    height: auto;
    background-color: #DDC7BB;
    display: grid;
    padding-top: 4.2rem;
    padding-bottom: 4.2rem;
    margin: 0;
    grid-template-columns: 5% 25% 20% 15% 15% 15% 5%;
    position: relative;
    bottom: 0;
    left: 0;
}

.footer_titles
{
    grid-column-start: 2;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
}



.footer_titles_2
{
    grid-column-start: 2;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    flex-direction: column;
}



.footer_h1,.footer_h3
{
    color: #2A1B12;
    margin: 0;
}

.footer_h5
{
    color: #4F3527;
    margin: 0.5rem;
    cursor: pointer;
    transition: color 0.5s ease, transform 0.3s ease;
}

.footer_h5:hover {
    color: white; /* Change color to white on hover */
    transform: scale(1.2); /* Scale up to 1.1 on hover */
}

.text_wrap
{
    width: 100%;
    height: 100%;
}

.Support
{

    grid-column-start: 4;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: start;
    justify-content: start;
    flex-direction: column;
}

.Pages
{

    grid-column-start: 5;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: start;
    justify-content: start;
    flex-direction: column;
}

.Our_Social
{

    grid-column-start: 6;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: start;
    justify-content: start;
    flex-direction: column;
}

.copyrights
{
    width: 100vw;
    height: auto;
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    cursor: pointer;
}

/* Tablet */
@media only screen and (min-width: 768px) and (max-width: 1024px) {
    

    .footer
    {
        width: 100%;
        height: auto;
        background-color: #DDC7BB;
        display: grid;
        padding-top: 4.2rem;
        padding-bottom: 4.2rem;
        margin: 0;
        grid-template-columns: 5% 45% 1% 4% 20% 20% 4%;
        position: relative;
        bottom: 0;
        left: 0;
    }

   
    
  }

/* Mobile */
@media only screen and (max-width: 767px) {
    
    .footer
    {
        width: 100vw;
        text-align: center;
        height: auto;
        overflow: hidden;
        background-color: #DDC7BB;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        padding-top: 2.2rem;
        padding-bottom: 2.2rem;
        bottom: 0;
    }


    .Pages
    {

        width: 100%;
        height: 100%;
        padding-top: 1.1rem;
        padding-bottom: 1.1rem;
        
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    .Our_Social
    {

        width: 100%;
        height: 100%;
        padding-top: 1.1rem;
        padding-bottom: 1.1rem;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

   


    .footer_h1
    {
        display: none;
    }

    .footer_h3
    {
        font-size: x-large;
        margin: 0;  
    }

    .footer_h1,.footer_h5
    {
        font-size: large;
        margin: 0;
    }

 }
  
  