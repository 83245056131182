.product_details_layout
{
    width: 100vw;
    height: auto;
    overflow: hidden;
    display: grid;
    grid-template-columns: 40% 60%;
}

.product_details_image
{
    width: 100%;
    grid-column-start: 1;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.product_details_desc
{
    width: 92%;
    grid-column-start: 2;
    height: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
}

.order_now
{
    padding-top: 1.4rem;
    padding-bottom: 1.4rem;
    width: 100%;
    border-radius: 1.1rem;
    background-color: #2B1B12;
    color: white;
    outline: none;
    border: none;
}

.product_image
{
    max-width: 96%;
    height: auto;
}



/* Tablet */
@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .product_details_layout
    {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    .product_image
    {
        max-width: 70%;
        height: auto;
    }
   
}

/* Mobile */
@media only screen and (max-width: 767px) {
    
    .product_details_layout
    {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    .product_image
    {
        max-width: 100%;
        height: auto;
    }



}